<template>
  <div class="question-container">
    <div class="question-header">
      <div
        class="title"
        style="margin-bottom: 100px;"
      >
        {{ question }}
      </div>
      <div
        class="description"
      >
        {{ description }}
      </div>
    </div>
    <div
      v-if="responses"
      class="question-responses question-responses-slider"
    >
      <div
        v-for="(choice, index) in choices"
        :key="question+''+index"
        class="level-group-slider"
      >
        <div
          class="label"
        >
          {{ choice.label }}
        </div>
        <div
          class="choice-slider"
        >
          <div v-if="selected">
            <c-level
              :selected="selected[index]"
              :index="index"
              :marks="choice.value.label"
              :step="1"
              :show-stops="false"
              :max="max"
              :min="min"
              :on-click="getChoice"
              :not-enabled="notEnabled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Level from './Level.vue';

export default {
  name: 'LevelGroup',
  components: {
    'c-level': Level,
  },
  props: {
    question: String,
    description: String,
    choices: Array,
    max: Number,
    min: Number,
    onClick: Function,
    selected: Array,
    notEnabled: Boolean,
    responseGetted: Boolean,
  },
  data() {
    return {
      responses: this.selected,
      timeout: null,
      preventPOST: false,
    };
  },
  watch: {
    selected(newValue) {
      this.responses = newValue;
      this.preventPOST = true;
    },
  },
  /*  created() {
    if (!this.notEnabled && !this.preventPOST) {
      this.onClick(this.selected);
    }
  }, */
  methods: {
    async getChoice(value, index) {
      this.responses[index] = value;

      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = await setTimeout(async () => {
        this.onClick(this.responses);
      }, 500);
    },
  },

};
</script>
